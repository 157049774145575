<template ref="adminUser">
  <div>
      <header-admin-pages
        title="Аккредитация СМИ"
        :counter="$store.getters.getAccreditation.length"
      />
      <form @submit.prevent="submitFilter" @reset="resetFilter"  class="filters">
        <input  v-model="filers.title"  placeholder=Введите заголовок type="text" class="input blue">
        <div class="wrapper-btn-filters">
          <input  class="filters-reset" type="reset" value="Сбросить фильтры">
          <input class="btn-blue" type="submit" value="Найти">
        </div>
      </form>
    <form class="filters" @submit.prevent="emailMarket">
      <div>
        <tags @dataTags="dataTags" :startValue="getEmailMarket" placeholder="введите почтовые адреса через слэш" />
      </div>
      <div class="wrapper-btn-filters">
        <input class="btn-blue" type="submit" value="Сохранить">
      </div>
    </form>
      <main>
        <table>
          <tr>
            <td><p>СМИ</p></td>
            <td><p>Почта Сми</p></td>
            <td><p>Дата</p></td>
            <td><p>Дата создания</p></td>
            <td><p>Статус</p></td>
            <td class="drop-icon"></td>
          </tr>
          <tr v-for="item in getAccreditation" :key="`country${item.id}`">
            <td><p>{{ item.massMedia }}</p></td>
            <td><p>{{ item.email }}</p></td>
            <td><p>{{ new Date(item.date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }) }}</p></td>
            <td><p>{{ new Date(item.createDate).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }) }}</p></td>
            <td><p>{{ item.status }}</p></td>
            <td class="drop-icon">
              <drop-list @clickItem="clickItem" :idItem="item.id"/>
            </td>
          </tr>
        </table>
        <pagination class="pagination__block"
                    :per-page="getAccreditationPagination.limit"
                    :current="getAccreditationPagination.offset / getAccreditationPagination.limit + 1"
                    :total="getAccreditationPagination.total"
                    @page-changed="getPage"/>
      </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import Pagination from '@/components/Pagination'
import DropList from '@/components/Admin/DropList'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'Accreditation',
  data () {
    return {
      emails: [],
      filers: {
        title: ''
      }
    }
  },
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить СМИ ?')
        if (consent) {
          this.$store.dispatch('deleteAccreditation', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailAccreditation', value.id)
      }
    },
    getPage (page) {
      this.$store.commit('setAdminAccreditationPaginationOffset', (page - 1) * this.getAccreditationPagination.limit)
      this.$store.dispatch('setAccreditation')
    },
    submitFilter () {
      const formData = new FormData()
      formData.append('key', this.filers.title)
      console.log(formData.get('key'))
      this.$store.dispatch('setFilterPolls', formData)
    },
    resetFilter () {
      this.$store.dispatch('setPolls')
      this.filers.title = ''
    },
    dataTags (value) {
      this.emails = value
    },
    emailMarket () {
      this.$store.dispatch('updateEmailMarket', { title: 'Аккредитация СМИ', emails: this.emails })
    }
  },
  computed: {
    getEmailMarket () {
      return this.$store.getters.getEmailMarket
    },
    getAccreditation () {
      return [...this.$store.getters.getAccreditation].sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
    },
    getAccreditationPagination () {
      return this.$store.getters.getAccreditationPagination
    }
  },
  async mounted () {
    await this.$store.dispatch('setEmailMarket', 'Аккредитация СМИ')
    await this.$store.dispatch('setAccreditation')
  },
  components: {
    HeaderAdminPages,
    Pagination,
    DropList,
    Tags
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}
.drop-icon {
  width: 3rem;
}
</style>
